body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing:content-box;
}

code {
  font-family: 'Poppins', sans-serif;
}

.css-dv0j91-MuiDataGrid-root,.css-2ulfj5-MuiTypography-root,.css-nrdprl-MuiTypography-root{
  font-family: 'Poppins', sans-serif !important;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #cac9c9; 
  border-radius: 10px;
}