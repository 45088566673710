.contentss {
    -webkit-transition: margin 225ms  ease-in-out;
    -moz-transition: margin 225ms  ease-in-out;
    -o-transition: margin 225ms  ease-in-out;
    transition: margin 225ms  ease-in-out;
    margin-left: 240px;
}

.contents {
    -webkit-transition: margin 225ms  ease-in-out;
    -moz-transition: margin 225ms  ease-in-out;
    -o-transition: margin 225ms  ease-in-out;
    transition: margin 225ms  ease-in-out;
    margin-left: 85px;
}


